import React, { Component } from "react";
import { Link } from "react-router-dom";

class BlogContent extends Component {
  render() {
    return (
      <>
        <br />
        <h4 className="code-line" data-line-start="0" data-line-end="1">
          <p id="De_los_fabricantes_Nmero_1__de_Tapetes_Antifatiga_en_Mxico_0"></p>
          De los fabricantes Número “1” de Tapetes Antifatiga en México
        </h4>
        <br />
        <p className="has-line-data" data-line-start="2" data-line-end="3">
          Las extremidades inferiores del cuerpo humano son los grandes
          olvidados en cuestiones de salud. Una de las partes más invisibles de
          nuestro cuerpo y las más importantes. Toda el balance y estabilidad
          del cuerpo depende de ellos, soportan nuestra carga, son los que nos
          mantienen y desplazan. ¿Alguna vez te preocupas de su cuidado?
        </p>
        <p className="has-line-data" data-line-start="4" data-line-end="5">
          Aunque por sí mismos poseen una amortiguación natural para no recibir
          la presión directa del suelo, no siempre pueden con todo, como por
          ejemplo, con entornos laborales que requieren permanecer en suelos
          duros y muchas horas de pie en el mismo sitio.
        </p>
        <p className="has-line-data" data-line-start="6" data-line-end="7">
          La frase de “Hasta que el cuerpo aguante” se ha quedado anticuada ya
          hace tiempo. El cuerpo nos sostiene y hay que ayudarle para que esté
          lo más confortable posible. Es justo por ello que el tapete antifatiga
          de DACSAseguridad es la solución perfecta para cuidar de pies y
          cuerpo.
        </p>
        <br />
        <br />
        <h2 className="code-line" data-line-start="8" data-line-end="9">
          <p id="Qu_les_ocurre_a_los_trabajadores_que_pasan_muchas_horas_de_pie_en_el_mismo_lugar_sin_tapete_antifatiga_8"></p>
          ¿Qué les ocurre a los trabajadores que pasan muchas horas de pie en el
          mismo lugar sin tapete antifatiga?
        </h2>
        <p className="has-line-data" data-line-start="10" data-line-end="11">
          La seguridad laboral de tus trabajadores es vital para tu empresa y
          debes tener en cuenta comenzar por cuidar su postura y evitar dentro
          de lo posible todas las lesiones que se puedan ocasionar. Un tapete
          antifatiga puede ser el mejor método. Cuando permanecemos de pie en el
          mismo lugar sin movernos, el cansancio en pies, piernas y espalda
          puede hacer mella. No solo se resienten los pies, sino que se pueden
          ocasionar lesiones musculares de distintos tipos en todo el cuerpo.
          Aunque el problema comience en nuestras extremidades inferiores, puede
          acabar en el cuello y en un dolor generalizado corporal, llegando a
          provocar dolores de espalda, inflamación en las piernas, problemas de
          circulación, inflamación en los talones, etc… ¿Conoces los costos por
          disminución en la producción derivado del ausentismo laboral?
        </p>
        <br />
        <br />
        <h2 className="code-line" data-line-start="12" data-line-end="13">
          <p id="Qu_hay_de_malo_en_un_suelo_duro_y_fro_frente_a_un_tapete_antifatiga_12"></p>
          ¿Qué hay de malo en un suelo duro y frío frente a un tapete
          antifatiga?
        </h2>
        <p className="has-line-data" data-line-start="14" data-line-end="16">
          Un suelo frío es una superficie incómoda para trabajar y su dureza no
          es adecuada para permanecer en él largos periodos.
          <br />
          Si nos movemos de un lado para otro y caminamos no tendremos tantos
          problemas, pero si los trabajadores están quietos durante muchas horas
          y sin modificar su postura, sus músculos se terminan contrayendo y
          ocasionando una peor circulación sanguínea. En resumen, el tapete
          antifatiga evita que esto ocurra y lo que hace es absorber el golpe
          que recibe el pie y la presión continua sobre el suelo. Es por ello,
          que un suelo suave y flexible como el que brinda un piso antifatiga es
          la mejor opción para evitar las inconveniencias del suelo liso, ya que
          cuando se utilizan estas alfombras acolchadas, los músculos de las
          piernas y pies se ven obligados a moverse arriba y abajo, evitando la
          inmovilidad continua.
        </p>
        <br />
        <br />
        <h3 className="code-line" data-line-start="17" data-line-end="18">
          <p id="Tapete_antifatiga_Pero_qu_es_eso_17"></p>¿Tapete antifatiga?
          ¿Pero qué es eso?
        </h3>
        <p className="has-line-data" data-line-start="19" data-line-end="25">
          Si estás preocupado por el bienestar de los trabajadores de tu
          empresa, cualquier medida que tomes para prevenir problemas en su
          salud se convierte en imprescindible.
          <br />
          Si no has escuchado antes hablar de un tapete antifatiga, estos son
          una solución esencial para suelos en entornos laborales, desde
          fábricas, líneas de producción de almacenes u otros usos industriales.
          <br />
          Pero los tapetes antifatiga no solo sirven para instalarlos en estos
          lugares, también se pueden colocar en jardines, cocinas o en el propio
          hogar.
          <br />
          Un tapete antifatiga es una alfombra ergonómica con una superficie
          irregular acolchada. Es un suelo básicamente, pero mucho más
          acolchado. Pueden ser de distintos materiales, como por ejemplo, el
          caucho de nitrilo o de poliuretano.
          <br />
          Los tapetes antifatiga de poliuretano están más indicados para zonas
          secas o algo húmedas, pero no abrasivas. En general, entre sus
          propiedades están la resistencia y que son antiestáticos. Además
          podemos encontrar tipos de tapete antifatiga en rollo.
          <br />
          En general, son retardantes del fuego y su diseño proporciona confort
          en piernas, pies y espalda. El color más habitual es el negro y cada
          pieza puede tener unas dimensiones según el fabricante
        </p>
        <br />
        <br />
        <h2 className="code-line" data-line-start="26" data-line-end="27">
          <p id="Cmo_es_la_superficie_de_un_tapete_antifatiga_26"></p>¿Cómo es
          la superficie de un tapete antifatiga?
        </h2>
        <br />
        <img
          style={{ width: "100%", height: "550px" }}
          src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antifatiga/Antifatiga_01.jpg"
        ></img>
        <br />
        <br />
        <p className="has-line-data" data-line-start="28" data-line-end="29">
          Como hemos visto no solo adquirir una buena postura es esencial, el
          suelo que pisamos mientras trabajamos también lo es. La superficie de
          los tapetes antifatiga es acolchada y puede cubrir superficies
          pequeñas o grandes ya que son ensamblables.
        </p>
        <p className="has-line-data" data-line-start="30" data-line-end="32">
          Su tecnología está diseñada para absorber los impactos y permite un
          mayor movimiento muscular.
          <br />
          Podemos encontrar tapetes antifatiga con estructura diamantada,
          superficies corrugadas, superficies de burbujas, de esponja, o por
          ejemplo con orificios.
        </p>
        <br />
        <br />
        <h2 className="code-line" data-line-start="34" data-line-end="35">
          <p id="Cules_son_los_beneficios_que_me_proporciona_el_tapete_antifatiga_34"></p>
          ¿Cuáles son los beneficios que me proporciona el tapete antifatiga?
        </h2>
        <br />
        <img
          style={{ width: "100%" }}
          src="https://static.wixstatic.com/media/d7e5d2_5bd9cbf2d6cf4f31a2ba5c4d6388b391~mv2.gif"
        ></img>
        <br />
        <br />
        <p className="has-line-data" data-line-start="35" data-line-end="36">
          Entre los incontables beneficios que encontramos en los tapetes
          antifatiga, aquí tenemos una gran muestra, no dudes que a larga solo
          recibirás incontables ventajas de su implantación:
        </p>
        <br />
        <h3 className="code-line" data-line-start="37" data-line-end="38">
          <p id="_Retarda_la_aparicin_del_cansancio_y_aumenta_el_bienestar_37"></p>
          ➥ Retarda la aparición del cansancio y aumenta el bienestar
        </h3>
        <p className="has-line-data" data-line-start="39" data-line-end="42">
          El bienestar de los trabajadores va a aumentar considerablemente con
          una mejor circulación de pies y piernas evitando la sobrecarga y
          mejorando su bienestar y comodidad. Con este tipo de suelo se retarda
          la aparición del cansancio y se crea un lugar confortable de trabajo.
          <br />
          Decía el músico polaco Fryderyk Chopin “Mientras tenga salud y
          fortaleza, trabajaré gustosamente todos mis días”.
          <br />
          Está claro que la salud física influye directamente en la salud mental
          y viceversa. Un entorno de trabajo agradable, sin tensiones físicas
          proporciona las mejores condiciones para sacar las tareas y labores
          diarias sin estrés ni tensiones innecesarias.
        </p>
        <br />
        <h3 className="code-line" data-line-start="43" data-line-end="44">
          <p id="_Menos_ausentismo_43"></p>➥ Menos ausentismo
        </h3>
        <p className="has-line-data" data-line-start="45" data-line-end="48">
          Según la STPS durante el periodo de enero a junio de 2017 se
          registraron 291.267 accidentes de trabajo con baja, un 5,5% más que el
          mismo periodo del año anterior, los cuales en su mayor parte
          ocurrieron durante la jornada laboral y por sobreesfuerzo físico.
          <br />
          Definitivamente, menos lesiones de trabajadores equivalen a menos
          ausencias laborales. Instalando tapetes antifatiga se evita el
          desgaste físico de los trabajadores y aumenta el nivel de eficiencia
          en el desempeño del puesto de trabajo.
          <br />
          Evitar problemas físicos debe ser una prioridad siempre en cualquier
          lugar, pero mucho más en la empresa.
        </p>
        <br />
        <h3 className="code-line" data-line-start="49" data-line-end="50">
          <p id="_Mejora_la_productividad_del_trabajador_49"></p>➥ Mejora la
          productividad del trabajador
        </h3>
        <p className="has-line-data" data-line-start="51" data-line-end="53">
          La fatiga del trabajador produce malestar y falta de concentración.
          Una mayor productividad será reflejada también gracias al uso del
          tapete antifatiga, al reducir su esfuerzo corporal.
          <br />
          Los trabajadores percibirán notablemente como el dolor y las molestias
          producidas por su postura serán menores. Trabajarán más a gusto, y las
          horas invertidas se les pasarán mucho más rápido al sentirse más
          cómodos.
        </p>
        <br />
        <h3 className="code-line" data-line-start="54" data-line-end="55">
          <p id="_Evita_cadas_y_resbalones_54"></p>➥ Evita caídas y resbalones
        </h3>
        <p className="has-line-data" data-line-start="56" data-line-end="57">
          Además de proporcionar una mayor higiene y limpieza, los tapetes
          antifatiga poseen superficies antideslizantes perfectas para evitar
          accidentes laborales. También, existen alfombras que poseen orificios
          y permiten que se drenen los líquidos en caso de que caigan,
          aumentando la seguridad.
        </p>
        <br />
        <br />
        <h2 className="code-line" data-line-start="58" data-line-end="59">
          <p id="Tapetes_antifatiga_precios_58"></p>Tapetes antifatiga: precios
        </h2>
        <p className="has-line-data" data-line-start="60" data-line-end="61">
          Los precios de los tapetes en el mercado son variables según el modelo
          que se requiera y de la superficie que se necesite cubrir. Puedes
          informarte de los precios y la solución más adecuada para tu empresa o
          solicita una asesoría para resolver todas tus dudas.
        </p>
        <br />
        <br />
        <h2 className="code-line" data-line-start="62" data-line-end="63">
          <p id="Ya_tengo_tapetes_antifatiga_Qu_ms_medidas_puedo_aplicar_para_la_seguridad_en_mi_entorno_laboral_62"></p>
          Ya tengo tapetes antifatiga ¿Qué más medidas puedo aplicar para la
          seguridad en mi entorno laboral?
        </h2>
        <p className="has-line-data" data-line-start="64" data-line-end="65">
          Aparte de instalar tapetes antifatiga para tus trabajadores, se
          recomiendan otras medidas a considerar para reducir lo más posible los
          problemas de salud. No solo el tapete de piso cobra importancia,
          también el calzado que use el trabajador, la movilidad que tenga en su
          puesto de trabajo y el material del que esté hecho el suelo.
        </p>
        <br />
        <h3 className="code-line" data-line-start="66" data-line-end="67">
          <p id="_Modifique_y_facilite_cambios_de_postura_66"></p>➥ Modifique y
          facilite cambios de postura
        </h3>
        <p className="has-line-data" data-line-start="68" data-line-end="69">
          Si puedes, facilita cambios de posición de tus trabajadores en el
          puesto de trabajo permitiendo que estos puedan cambiar de postura
          frecuentemente.
        </p>
        <br />
        <h3 className="code-line" data-line-start="70" data-line-end="71">
          <p id="_Utilizacin_de_un_zapato_adecuado_70"></p>➥ Utilización de un
          zapato adecuado
        </h3>
        <p className="has-line-data" data-line-start="72" data-line-end="74">
          También debes tener en cuenta que los empleados lleven un zapato
          adecuado en su puesto de trabajo y que este, no sea totalmente plano,
          ya que resulta inadecuado para los pies.
          <br />
          Además, se pueden usar unas buenas plantillas o almohadillado para
          rebajar la tensión en la pisada.
        </p>
        <br />
        <h3 className="code-line" data-line-start="75" data-line-end="76">
          <p id="_Tenga_en_cuenta_el_material_del_suelo_75"></p>➥ Tenga en
          cuenta el material del suelo
        </h3>
        <p className="has-line-data" data-line-start="77" data-line-end="78">
          El material del suelo no se queda atrás. Instalar suelos de materiales
          como madera, corcho, alfombra o hule, da más elasticidad que un suelo
          de cemento puro y duro.
        </p>
        <br />
        <h3 className="code-line" data-line-start="79" data-line-end="80">
          <p id="_Promueva_los_ejercicios_posturales_79"></p>➥ Promueva los
          ejercicios posturales
        </h3>
        <p className="has-line-data" data-line-start="81" data-line-end="85">
          Y yendo más allá, existen ejercicios muy adecuados de Pilates y otras
          técnicas como el yoga que fortalecen la espalda y corrigen posturas
          inadecuadas evitando una sobrecarga muscular en el entorno laboral.
          <br />
          Las soluciones para suelos en empresas son cada vez más demandadas,
          así como los tapetes antifatiga y distintos tipos de alfombras para
          variados usos.
          <br />
          En DACSAseguridad esperamos que este post te haya sido útil para
          resolver tus dudas y poder ahondar en la importancia que supone
          instalar este tipo de tapete antifatiga en tu lugar de trabajo. Sobre
          todo, en los grandes beneficios y ventajas que estarás brindando a tus
          trabajadores.
          <br />
          Si aun así te has quedado con dudas o quieres ampliar tu información,
          no dudes en contactarnos. Pondremos nuestros más de treinta de años de
          experiencia a tu servicio.
        </p>
      </>
    );
  }
}

export default BlogContent;
