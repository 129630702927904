import React, { Component } from "react";
import Header from "./layouts/Header";
import Banner from "./layouts/Banner";
import Features from "./layouts/Features";
import AboutArea from "./layouts/AboutArea";
import ServiceArea from "./layouts/ServiceArea";
import WhyChooseUs from "./layouts/WhyChooseUs";
import RecentProducts from "./layouts/RecentProducts";
import Cta from "./layouts/Cta";
import BlogArea from "./layouts/BlogArea";
import Brands from "./layouts/Brands";
import Footer from "./layouts/Footer";

class Home extends Component {
  render() {
    return (
      <>
        <Header />
        <Banner />
        <Features />
        <AboutArea design={"home_1"} />
        <ServiceArea background={true} />
        <WhyChooseUs />
        {/* <RecentProducts /> */}
        <Cta />
        <BlogArea />
        {/* <Brands /> */}
        <Footer />
      </>
    );
  }
}

export default Home;
