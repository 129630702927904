import React, { Component } from "react";
import { Link } from "react-router-dom";

class ServiceArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: "Antifatiga",
    };
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  sendProduct = (e) => {
    this.setState({ product: e.target.id });
  };

  render() {
    return (
      <section
        className="section cm-services bg-cover overlay-dark"
        style={{
          backgroundImage:
            "url(https://dacsa-web-media.s3.us-east-2.amazonaws.com/banners/mat-banner.jpeg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="flex-row nav nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="horizontal"
            >
              <a
                className="nav-link rounded-0 text-white text-left active"
                data-toggle="pill"
                href="#one"
                role="tab"
                aria-selected="true"
              >
                TAPETE ANTIFATIGA
              </a>
              <a
                className="nav-link rounded-0 text-white text-left"
                data-toggle="pill"
                href="#two"
                role="tab"
                aria-selected="false"
              >
                TAPETE PUBLICITARIO
              </a>
              <a
                className="nav-link rounded-0 text-white text-left"
                data-toggle="pill"
                href="#three"
                role="tab"
                aria-selected="false"
              >
                TAPETE ANTIESTÁTICO
              </a>
              <a
                className="nav-link rounded-0 text-white text-left"
                data-toggle="pill"
                href="#four"
                role="tab"
                aria-selected="false"
              >
                TAPETE DIELÉCTRICO
              </a>
            </div>
          </div>
          <div className="row">
            <div className="flex-row">
              <div className="col-lg-12">
                <div className="tab-content mt-50" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="one"
                    role="tabpanel"
                  >
                    <div className="service-item">
                      <div className="row">
                        <div className="col-md-12">
                          <h2 className={"h1 text-white mb-25"}>
                            TAPETE ANTIFATIGA
                          </h2>
                        </div>
                        <div className="col-md-5 order-1 order-md-0">
                          <br />
                          <br />
                          <h4 className="text-gray">
                            ¿Cómo sé cuál es el tapete ideal para mi aplicación?
                          </h4>
                          <br />
                          <p className="text-gray">
                            Es importante adquirir el tapete correcto para su
                            aplicación desde el primer momento. Nosotros podemos
                            ayudarle. nuestro soporte en línea le ayudara a
                            seleccionar el producto industrial que necesitas.
                            Además, ofrecemos un blog para comenzar a conocer
                            los productos que le ayudará a familiarizarse con la
                            ciencia que se utiliza detrás de la tecnología.
                          </p>
                          <Link
                            id="Antifatiga"
                            to={{
                              pathname: "/shop-single",
                              state: { product: "Antifatiga" },
                            }}
                            className="btn btn-primary mt-35 mr-15 initiate-scripts"
                            onClick={this.scrollToTop()}
                          >
                            Más Detalles
                          </Link>
                          <Link
                            to={"/contact"}
                            className="btn btn-primary mt-35 initiate-scripts"
                          >
                            Contáctanos
                          </Link>
                        </div>
                        <div className="col-md-5 col-sm-8 order-0 order-md-1 my-1 mb-4 my-md-0">
                          <img
                            className="img-fluid"
                            src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antifatiga/antifatiga-home-1.jpeg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/* service-item */}
                  </div>
                  <div className="tab-pane fade" id="two" role="tabpanel">
                    <div className="service-item">
                      <div className="row">
                        <div className="col-md-12">
                          <h2 className={"h1 text-white mb-25"}>
                            TAPETE PUBLICITARIO
                          </h2>
                        </div>
                        <div className="col-md-5">
                          <br />
                          <br />
                          <p className="text-gray">
                            Los tapetes de PVC tipo Rizo o Espagueti con
                            logotipo, son ideales para entradas interiores y
                            exteriores por su protección UV, ya que reducen la
                            entrada de polvo y humedad.
                          </p>
                          <Link
                            to={{
                              pathname: "/shop-single",
                              state: { product: "Publicitario" },
                            }}
                            className="btn btn-primary mt-35 mr-15 initiate-scripts"
                          >
                            Más Detalles
                          </Link>
                          <Link
                            to={"/contact"}
                            className="btn btn-primary mt-35 initiate-scripts"
                          >
                            Contáctanos
                          </Link>
                        </div>
                        <div className="col-md-5">
                          <img
                            className="img-fluid"
                            src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/publicitario/publicitario-home-1.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/* service-item */}
                  </div>
                  <div className="tab-pane fade" id="three" role="tabpanel">
                    <div className="service-item">
                      <div className="row">
                        <div className="col-md-12">
                          <h2 className={"h1 text-white mb-25"}>
                            TAPETE ANTIESTÁTICO
                          </h2>
                        </div>
                        <div className="col-md-5">
                          <br />
                          <p className="text-gray">
                            Aporta mejoras de tu infraestructura usando tapetes
                            Antiestáticos para el control de la energía estática
                            en donde realmente se necesita.
                            <br />
                            <br />
                            No dañes los suministros electrónicos en tu proceso
                            de producción.
                            <br />
                            <br />
                            Reduce las molestas descargas a los operadores por
                            la generación de energía de tu proceso.
                            <br />
                            <br />
                            Asegura tus equipos de comunicación o servidores en
                            tu site de telecomunicaciones
                          </p>
                          <Link
                            to={{
                              pathname: "/shop-single",
                              state: { product: "Antiestatico" },
                            }}
                            className="btn btn-primary mt-35 mr-15 initiate-scripts"
                          >
                            Más Detalles
                          </Link>
                          <Link
                            to={"/contact"}
                            className="btn btn-primary mt-35 initiate-scripts"
                          >
                            Contáctanos
                          </Link>
                        </div>
                        <div className="col-md-5">
                          <img
                            className="img-fluid"
                            src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antiestatico/antiestatico-home-1.jpeg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/* service-item */}
                  </div>
                  <div className="tab-pane fade" id="four" role="tabpanel">
                    <div className="service-item">
                      <div className="row">
                        <div className="col-md-12">
                          <h2 className={"h1 text-white mb-25"}>
                            TAPETE DIELÉCTRICO
                          </h2>
                        </div>
                        <div className="col-md-5">
                          <br />
                          <br />
                          <p className="text-gray">
                            El tapete dieléctrico se utiliza para ser colocado
                            frente a subestaciones eléctricas, centros de carga
                            e interruptores de cuchilla, para proteger al
                            personal de una descarga eléctrica Cuenta con una
                            superficie corrugada resistente para obtener
                            tracción adicional.
                          </p>
                          <Link
                            to={{
                              pathname: "/shop-single",
                              state: { product: "Dielectrico" },
                            }}
                            className="btn btn-primary mt-35 mr-15 initiate-scripts"
                          >
                            Más Detalles
                          </Link>
                          <Link
                            to={"/contact"}
                            className="btn btn-primary mt-35 initiate-scripts"
                          >
                            Contáctanos
                          </Link>
                        </div>
                        <div className="col-md-5">
                          <img
                            className="img-fluid"
                            src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/dielectrico/dielectrico-home-1.jpeg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/* service-item */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ServiceArea;
