import React, { Component } from "react";

class Brands extends Component {
  render() {
    return (
      <section className="section cm-brands">
        <div className="container">
          <div className="row">
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/01.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/02.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/03.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/04.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/05.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/06.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/07.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/08.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/09.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/10.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/02.jpg"}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
              <img
                className="img-fluid mt-40"
                src={process.env.PUBLIC_URL + "/assets/images/brands/03.jpg"}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Brands;
