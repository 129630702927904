import React, { Component } from "react";
import { Link } from "react-router-dom";

const strings = require("../strings/products.json");

const products = {
  Antifatiga: "antifatiga",
  Publicitario: "publicitario",
  Antiestatico: "antiestatico",
  Dielectrico: "dielectrico",
};

class PageDetails extends Component {
  render() {
    const product = products[this.props.product];

    return (
      <>
        <div className="tab-pane fade show active" id="desc" role="tabpanel">
          <div className="pt-30 justify-text">
            {this.props.product === "Antifatiga" && (
              <>
                <p>
                  {strings.productDetails[product].descriptionQ1}
                  <br />
                  <br />
                  {strings.productDetails[product].descriptionText1}
                </p>
                <p className="mt-50 justify-text">
                  {strings.productDetails[product].descriptionQ2}
                  <br />
                  <br />
                  {strings.productDetails[product].descriptionText2}
                </p>
              </>
            )}
            {this.props.product === "Publicitario" && (
              <>
                <p>
                  {strings.productDetails[product].descriptionQ1}
                  <br />
                  <br />
                  {strings.productDetails[product].descriptionText1}
                  <br />
                  <br />
                  {strings.productDetails[product].descriptionQ2}
                </p>
                <p className="mt-50 justify-text">
                  <p>
                    Ideal para la protección del piso y prevención de accidentes
                    y dar imagen corporativa al lugar.z
                  </p>
                  <br />
                  <p>Podemos plasmar tu diseño a la medida que necesitas.</p>
                  <br />
                  <p>Ponemos su logo o imagen en un tapete de entrada.</p>
                  <br />
                  <p>
                    Fabricamos desde 1 tapete y atendemos pedidos de gran
                    volumen
                  </p>
                  <br />
                  <p>Todos nuestros tapetes tienen garantía.</p>
                  <br />
                  <p>
                    El precio varia de acuerdo solo a las medidas no a la
                    cantidad de colores. Solicite cotización sin compromiso…
                  </p>
                  <br />
                  <p>Hacemos pedidos URGENTES</p>
                </p>
              </>
            )}
            {this.props.product === "Antiestatico" && (
              <>
                <p>
                  {strings.productDetails[product].descriptionQ1}
                  <br />
                  <br />
                  {strings.productDetails[product].descriptionText1}
                </p>
                <p className="mt-50 justify-text">
                  <p>
                    “La mejor” base de esponja La base de esponja Nitricell
                    ofrece doble elasticidad y triple vida útil en comparación
                    con las esponjas comunes
                  </p>
                  <br />
                  <p>
                    Diseño plano y hasta un 20 % más de firmeza en áreas donde
                    se permanece de pie, en comparación con productos parecidos
                  </p>
                  <br />
                  <p>
                    Espesor general: estándar 9/16 pul., UltraSoft 15/16 pul.
                  </p>
                  <br />
                  <p>
                    El broche de puesta a tierra se debe colocar en la esquina
                    de cada tapete (NOTA: El tapete debe conectarse a tierra con
                    un cable de puesta a tierra; los trabajadores deben usar
                    taloneras o calzado con suelas conductivas )
                  </p>
                  <br />
                  <p>
                    En DACSA, ofrecemos la solución definitiva a los problemas
                    de fatiga aplicando la tecnología ergonómica a cada uno de
                    nuestros tapetes.
                  </p>
                </p>
              </>
            )}
            {this.props.product === "Dielectrico" && (
              <>
                <p>
                  {strings.productDetails[product].descriptionQ1}
                  <br />
                  <br />
                  {strings.productDetails[product].descriptionText1}
                  <br />
                  <br />
                  {strings.productDetails[product].descriptionQ2}
                </p>
                <p className="mt-50 justify-text">
                  {strings.productDetails[product].descriptionText2}
                </p>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default PageDetails;
