import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <>
        {/* start footer */}
        <footer
          className="footer section bg-cover overlay-dark-footer pt-60"
          style={{
            backgroundImage:
              "url(https://dacsa-web-media.s3.us-east-2.amazonaws.com/banners/footer-img.jpeg)",
            maxHeight: 300,
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-md-6">
                <img
                  className="mb-25"
                  src={
                    "https://dacsa-web-media.s3.us-east-2.amazonaws.com/logos/logo.png"
                  }
                  alt=""
                />
                <ul className="social-links list-unstyled mt-25">
                  <li>
                    <a
                      href={"https://wa.me/524425925518"}
                      className="initiate-scripts"
                    >
                      <span className="fab fa-whatsapp" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://www.facebook.com/Dacsaseguridad/"}
                      className="initiate-scripts"
                    >
                      <span className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://instagram.com"}
                      className="initiate-scripts"
                    >
                      <span className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://youtube.com"}
                      className="initiate-scripts"
                    >
                      <span className="fab fa-youtube" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://linkedin.com"}
                      className="initiate-scripts"
                    >
                      <span className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-xl-3 col-md-6">
                {/* <h4 className="font-w-600 text-white mb-10">¡Contáctanos!</h4> */}
                <div className="text-gray mt-20">
                  <span className="icon text-primary fas fa-phone-alt mr-2" />
                  +52 (442) 4848 928
                </div>
                <div className="text-gray mt-20 dropdown-toggle">
                  <span className="icon text-primary fas fa-envelope mr-2" />
                  contacto@dacsaseguridad.com.mx
                </div>
                <div className="text-gray mt-20">
                  <span className="icon text-primary fas fa-home mr-2" />
                  Carretera al Tlacote, Parque Industrial Tlacote II, Queretaro,
                  QRO.
                </div>
                {/* <form className="mt-25" method="post">
                  <div className="input-group input-group-primary">
                    <input
                      type="email"
                      className="form-control text-white px-3 bg-transparent shadow-none border-0"
                      placeholder="Mail address"
                    />
                    <div className="input-group-apend">
                      <button
                        className="btn btn-primary rounded-circle"
                        type="submit"
                      >
                        <span className="fas fa-chevron-right" />
                      </button>
                    </div>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </footer>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>Copyright © 2021. All Rights Reserved By DACSA Seguridad</p>
              </div>
            </div>
          </div>
        </div>
        {/* end footer */}

        <a href="#top" className="scroll-to-top">
          <span className="fas fa-chevron-up" />
        </a>
        {/* scroll to top */}
      </>
    );
  }
}

export default Footer;
