import React, { Component } from "react";

class Features extends Component {
  render() {
    return (
      <div className="cm-features">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-md-6">
              <div className="block d-flex align-items-center">
                <span className="icon bg-primary cm-features-icon-container">
                <i class="fas fa-diagnoses cm-features-icon"></i>
                </span>
                <div>
                  <h5 className="text-dark mb-10">TAPETE ANTIFATIGA</h5>
                </div>
              </div>
              {/* features item */}
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="block d-flex align-items-center">
                <span className="icon bg-primary cm-features-icon-container">
                  <i class="fas fa-bullhorn cm-features-icon"></i>
                </span>
                <div>
                  <h5 className="text-dark mb-10">TAPETES PUBLICITARIO</h5>
                </div>
              </div>
              {/* features item */}
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="block d-flex align-items-center">
                <span className="icon bg-primary cm-features-icon-container">
                  <i className="fas fa-bolt cm-features-icon"></i>
                </span>
                <div>
                  <h5 className="text-dark mb-10">TAPETE DIELÉCTRICO</h5>
                </div>
              </div>
              {/* features item */}
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="block d-flex align-items-center">
                <span className="icon bg-primary cm-features-icon-container">
                  <i class="fas fa-vote-yea cm-features-icon"></i>
                </span>
                <div>
                  <h5 className="text-dark mb-10">TAPETE ANTIESTÁTICO</h5>
                </div>
              </div>
              {/* features item */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
