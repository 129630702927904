import React, { Component } from "react";
import Section from "./layouts/Section";
import { Link } from "react-router-dom";
import Gallery from "./layouts/Gallery";
import axios from "axios";
import PageDetails from "./layouts/ProductContent";

const strings = require("./strings/products.json");

const products = {
  Antifatiga: "antifatiga",
  Publicitario: "publicitario",
  Antiestatico: "antiestatico",
  Dielectrico: "dielectrico",
};

class ShopSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedProduct: this.props.location.state
        ? this.props.location.state.product
        : "Antifatiga",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  handleShownProduct = (e) => {
    this.setState({ displayedProduct: e.target.id });
    this.scrollToTop();
  };
  donwloadTechSpecs = async () => {
    await axios({
      method: "GET",
      url:
        "https://dacsa-web-media.s3.us-east-2.amazonaws.com/documents/ficha_tecnica_" +
        products[this.state.displayedProduct] +
        ".pdf",
      responseType: "arraybuffer",
    })
      .then((response) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download =
          "ficha_tecnica_" + products[this.state.displayedProduct] + ".pdf";

        document.body.appendChild(link);

        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
        this.scrollToTop();
        alert("¡Gracias por descargar nuestras fichas técnicas!");
      })
      .catch((error) => {
        alert("Error: Por favor intenta más tarde!");
      });
  };

  render() {
    const product = products[this.state.displayedProduct];
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    return (
      <>
        <Section title={"PRODUCTOS"}>
          <section className="section page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="product-img-style text-center">
                    <Gallery product={this.state.displayedProduct} />
                  </div>
                </div>
                <div className="col-lg-5 mt-5 mt-lg-0">
                  <h3 className="text-dark font-w-600 mt-10 mb-10">
                    {strings.productDetails[product].title}
                  </h3>
                  <p className="mt-50 justify-text">
                    {strings.productDetails[product].shortIntro}
                    <br />
                    <br />
                    {strings.productDetails[product].shortDescription}
                  </p>
                </div>
              </div>

              <div className="row mt-50">
                <div className="col-md-12">
                  <div className="border-bottom-tab">
                    <ul
                      className="nav nav-tabs justify-content-center"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link text-reset active"
                          id="desc-tab"
                          data-toggle="tab"
                          href="#desc"
                          role="tab"
                          aria-controls="desc"
                          aria-selected="true"
                        >
                          Descripción
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link text-reset"
                          id="info-tab"
                          data-toggle="tab"
                          href="#info"
                          role="tab"
                          aria-controls="info"
                          aria-selected="false"
                        >
                          Ficha Técnica
                        </a>
                      </li>
                      {/*                       
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link text-reset"
                          id="review-tab"
                          data-toggle="tab"
                          href="#review"
                          role="tab"
                          aria-controls="review"
                          aria-selected="false"
                        >
                          Reviews (5)
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link text-reset"
                          id="vendor-tab"
                          data-toggle="tab"
                          href="#vendor"
                          role="tab"
                          aria-controls="vendor"
                          aria-selected="false"
                        >
                          Vendor Info
                        </a>
                      </li>
                     */}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <PageDetails product={this.state.displayedProduct} />
                      <div className="tab-pane fade" id="info" role="tabpanel">
                        <div className="pt-30">
                          <h6 className="mb-20 text-dark">
                            En esta sección podrás encontrar la versión
                            descargable de nuestras fichas técnicas.
                          </h6>
                          <button
                            className="btn btn-secondary initiate-scripts"
                            onClick={this.donwloadTechSpecs}
                          >
                            Desgargar ficha técnica
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-60">
                <div className="col-md-12">
                  <h3 className="text-dark text-center mb-40">
                    Productos Relacionados
                  </h3>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card border-0 product-item shadow-sm mb-30">
                    <img
                      className="card-img-top border-bottom"
                      src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antifatiga/antifatiga-home-1.jpeg"
                      alt=""
                    />
                    <div className="card-body">
                      <div className="show-on-hover">
                        <button
                          id="Antifatiga"
                          onClick={this.handleShownProduct}
                          className="btn btn-primary rounded-pill initiate-scripts"
                        >
                          Ver Detalles <span className="fas fa-search" />
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="text-dark font-w-600 mt-10 mb-10">
                          Tapete Antifatiga
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card border-0 product-item shadow-sm mb-30">
                    <img
                      className="card-img-top border-bottom"
                      src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/publicitario/publicitario-home-1.jpg"
                      alt=""
                    />
                    <div className="card-body">
                      <div className="show-on-hover">
                        <button
                          id="Publicitario"
                          onClick={this.handleShownProduct}
                          className="btn btn-primary rounded-pill initiate-scripts"
                        >
                          Ver Detalles <span className="fas fa-search" />
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="text-dark font-w-600 mt-10 mb-10">
                          Tapete Publicitario
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card border-0 product-item shadow-sm mb-30">
                    <img
                      className="card-img-top border-bottom"
                      src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antiestatico/antiestatico-home-1.jpeg"
                      alt=""
                    />
                    <div className="card-body">
                      <div className="show-on-hover">
                        <button
                          id="Antiestatico"
                          onClick={this.handleShownProduct}
                          className="btn btn-primary rounded-pill initiate-scripts"
                        >
                          Ver Detalles <span className="fas fa-search" />
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="text-dark font-w-600 mt-10 mb-10">
                          Tapete Antiestático
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card border-0 product-item shadow-sm mb-30">
                    <img
                      className="card-img-top border-bottom"
                      src="https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/dielectrico/dielectrico-home-1.jpeg"
                      alt=""
                    />
                    <div className="card-body">
                      <div className="show-on-hover">
                        <button
                          id="Dielectrico"
                          onClick={this.handleShownProduct}
                          className="btn btn-primary rounded-pill initiate-scripts"
                        >
                          Ver Detalles <span className="fas fa-search" />
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="text-dark font-w-600 mt-10 mb-10">
                          Tapete Dieléctrico
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Section>
      </>
    );
  }
}

export default ShopSingle;
