import React, { Component } from "react";
import { Link } from "react-router-dom";

class AboutArea extends Component {
  render() {
    // const imagePart = (
    //   <div className="col-lg-6 col-md-8">
    //     <div className="pr-0 pr-lg-5">
    //       <img
    //         className="img-fluid"
    //         src={process.env.PUBLIC_URL + "/assets/images/about-image.jpg"}
    //         alt="about"
    //       />
    //     </div>
    //   </div>
    // );

    const descPart = (
      <div className="col-lg-6 col-md-10">
        <div
          className={
            this.props.design === "home_1"
              ? "block text-center mt-5 mt-lg-0"
              : "block"
          }
        >
          <h2 className="h1 text-dark">DACSA SEGURIDAD</h2>
          <p className="mt-25">
            Empresa 100% Mexicana dedicada satisfacer las necesidades
            Industriales de Ergonómia, eficientando los procesos productivos y
            garantizando la seguridad de nuestros Atletas Industriales.
          </p>
          {/* <Link
            to={"/services"}
            className="btn btn-primary mt-30 initiate-scripts"
          >
            learn more
          </Link> */}
        </div>
      </div>
    );
    return (
      <section
        className={
          this.props.design === "home_1"
            ? "section cm-about-us pt-80"
            : "section cm-about-us"
        }
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {this.props.design === "home_1" ? (
              <>
                {/* {imagePart} */}
                {descPart}
              </>
            ) : (
              <>
                {descPart}
                {/* {imagePart} */}
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default AboutArea;
