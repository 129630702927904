import React from "react";
import { Container } from "reactstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        0: {
          nav: false,
          dots: true,
        },
        767: {
          nav: true,
        },
      },
    };
  }

  render() {
    return (
      <>
        <section className="banner">
          <OwlCarousel
            className="banner-owl owl-theme"
            loop={true}
            margin={0}
            autoplay={true}
            autoplayTimeout={4000}
            smartSpeed={500}
            nav={true}
            items="1"
            responsive={this.state.responsive}
            dots={false}
          >
            <div className="item">
              <div className="banner-one banner-img">1 slide</div>
            </div>

            <div className="item">
              <div className="banner-two banner-img">2 slide</div>
            </div>

            <div className="item">
              <div className="banner-three banner-img">3 slide</div>
            </div>
          </OwlCarousel>

          <div className="banner-static-content">
            <Container>
              <div className="banner-text text-center">
                <h1 className="banner-header-text">DACSA Seguridad</h1>
                <br />
                <br />
                <h3 className="display-6 mt-25 mb-35">
                  Conocimientos que dan Seguridad{" "}
                </h3>
              </div>
            </Container>
          </div>
        </section>
      </>
    );
  }
}

export default Banner;
