import React, { Component } from "react";

class WhyChooseUs extends Component {
  render() {
    return (
      <section className="section why-choose-us">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center">
              <h2 className="section-heading h1 text-dark">
                ¿Por qué somos tu mejor opción?
              </h2>
              {/* <p className="mt-30">
                Do commanded an shameless we disposing do. Indulgence ten
                remarkably nor are impression out.
              </p> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 mt-4 mt-lg-0">
              <div className="row masonry">
                <div className="col-sm-3">
                  <div className="block text-center mt-30">
                    <div className="icon">
                      <img
                        src={"https://dacsa-web-media.s3.us-east-2.amazonaws.com/icons/EmpresaMexicana.svg"}
                        alt=""
                      />
                    </div>
                    <h4 className="mt-25 text-dark">EMPRESA MEXICANA</h4>
                    <br></br>
                    <p className="mt-20">
                      20 años de experiencia nos respaldan.
                    </p>
                  </div>
                  {/* why choose us item */}
                </div>
                <div className="col-sm-3">
                  <div className="block text-center mt-30">
                    <div className="icon">
                      <img
                        src={"https://dacsa-web-media.s3.us-east-2.amazonaws.com/icons/Calidad.svg"}
                        alt=""
                      />
                    </div>
                    <h4 className="mt-25 text-dark">CALIDAD</h4>
                    <br></br>
                    <br></br>
                    <p className="mt-20">
                      Utilizamos solo los mejores productos nacionales e
                      importados en nuestras fabricaciones.
                    </p>
                  </div>
                  {/* why choose us item */}
                </div>
                <div className="col-sm-3">
                  <div className="block text-center mt-30">
                    <div className="icon">
                      <img
                        src={"https://dacsa-web-media.s3.us-east-2.amazonaws.com/icons/Seguridad.svg"}
                        alt=""
                      />
                    </div>
                    <h4 className="mt-25 text-dark">SEGURIDAD</h4>
                    <br></br>
                    <br></br>
                    <p className="mt-20">
                      Tiempos de entrega cortos urgentes en todos nuestros
                      productos.
                    </p>
                  </div>
                  {/* why choose us item */}
                </div>
                <div className="col-sm-3">
                  <div className="block text-center mt-30">
                    <div className="icon">
                      <img
                        src={"https://dacsa-web-media.s3.us-east-2.amazonaws.com/icons/Innovacion.svg"}
                        alt=""
                      />
                    </div>
                    <h4 className="mt-25 text-dark">INNOVACIÓN</h4>
                    <br></br>
                    <br></br>
                    <p className="mt-20">
                      Investigación y desarrollo constante en ergonomía y
                      seguridad Industrial.
                    </p>
                  </div>
                  {/* why choose us item */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WhyChooseUs;
