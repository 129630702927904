import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <header id="home" className="cm-header">
        <div className="header">
          <div className="container h-100">
            <div className="row h-100 align-content-center">
              <div className="col-md-12">
                <nav className="navbar navbar-expand-lg navbar-light px-0">
                  <Link className="navbar-brand initiate-scripts" to={"/home"}>
                    <img
                      src={"https://dacsa-web-media.s3.us-east-2.amazonaws.com/logos/logo.png"}
                      alt="logo"
                    />
                  </Link>
                  <button
                    className="navbar-toggler border-0 p-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navLinks"
                    aria-controls="navLinks"
                    aria-expanded="false"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>

                  <div className="collapse navbar-collapse" id="navLinks">
                    <ul className="navbar-nav ml-auto align-items-center">
                      <li className="nav-item dropdown active">
                        <Link
                          className="nav-link initiate-scripts"
                          to={"/home"}
                          role="button"
                        >
                          DACSA
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link initiate-scripts"
                          to={"/shop-single"}
                        >
                          Productos
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link initiate-scripts"
                          to={"/contact"}
                        >
                          Contacto
                        </Link>
                      </li>
                      {/* <div className="block d-flex mt-3 mt-lg-0">
                        <span
                          className="icon fas fa-share-alt text-dark"
                          role="button"
                        />
                        <span
                          className="icon fas fa-search text-dark search-toggle"
                          role="button"
                        />
                        <Link
                          className="icon pr-0 pt-0 pb-0 text-dark initiate-scripts"
                          to={"/cart"}
                        >
                          <span className="fas fa-shopping-bag" />
                        </Link>
                      </div> */}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="header-top bg-blue">
          <div className="container">
            <div className="row">
              <div className="col-xl-10">
                <div className="d-block d-md-flex justify-content-center justify-content-xl-start">
                  <div className="block text-gray mr-35">
                    <span className="icon text-primary fas fa-phone-alt mr-15" />
                    442 4848928 | 442 7361620
                  </div>
                  <div className="block text-gray mr-35">
                    <span className="icon text-primary fas fa-envelope mr-15" />
                    contacto@dacsaseguridad.com.mx
                  </div>
                  {/* <div className="block text-gray">
                    <span className="icon text-primary fas fa-home mr-15" />
                    Carretera al Tlacote Parque Industrial Tlacote II Queretaro,
                    QRO. ​ ​
                  </div> */}
                </div>
              </div>
              <div className="col-xl-2 d-none d-xl-block">
                <div className="block d-flex justify-content-end">
                  <a
                    href={"https://wa.me/524425925518"}
                    className="text-primary initiate-scripts"
                  >
                    <span className="icon fab fa-whatsapp border-right-0" />
                  </a>
                  <a
                    href={"https://www.facebook.com/Dacsaseguridad/"}
                    className="text-primary initiate-scripts"
                  >
                    <span className="icon fab fa-facebook-f border-right-0" />
                  </a>
                  <a
                    href={"https://www.instagram.com"}
                    className="text-primary initiate-scripts"
                  >
                    <span className="icon fab fa-instagram border-right-0" />
                  </a>
                  <a
                    href={"https://www.youtube.com"}
                    className="text-primary initiate-scripts"
                  >
                    <span className="icon fab fa-youtube border-right-0" />
                  </a>
                  <a
                    href={"https://www.linkedin.com"}
                    className="text-primary initiate-scripts"
                  >
                    <span className="icon fab fa-linkedin-in border-right-0" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
