import React, { Component } from "react";
import { Router, Route, Switch, HashRouter } from "react-router-dom";
import ReactGA from "react-ga";
import history from "./History";
import Home from "./components/Home";
import Services from "./components/Services";
import Contact from "./components/Contact";
import BlogDetails from "./components/BlogDetails";
import ServiceSingle from "./components/ServiceSingle";
import ShopSingle from "./components/ShopSingle";
import Cart from "./components/Cart";
class Routes extends Component {
  componentDidMount = () => {
    ReactGA.initialize("UA-208778505-1");
    ReactGA.pageview("/");
  };

  onRouteChange(pageId) {
    ReactGA.pageview(pageId);
  }

  render() {
    return (
      <Router history={history}>
        <HashRouter basename="/">
          <Switch>
            <Route
              exact
              path={"/"}
              render={(props) => {
                this.onRouteChange("/");
                return <Home {...props} />;
              }}
            />
            <Route
              exact
              path={"/home"}
              render={(props) => {
                this.onRouteChange("/home");
                return <Home {...props} />;
              }}
            />
            <Route
              exact
              path={"/services"}
              render={(props) => {
                this.onRouteChange("/services");
                return <Services {...props} />;
              }}
            />
            <Route
              exact
              path={"/contact"}
              render={(props) => {
                this.onRouteChange("/contact");
                return <Contact {...props} />;
              }}
            />
            <Route
              exact
              path={"/blog-details"}
              render={(props) => {
                this.onRouteChange("/blog-details");
                return <BlogDetails {...props} />;
              }}
            />
            {/* <Route
              exact
              path={"/service-single"}
              render={(props) => <ServiceSingle {...props} />}
            /> */}
            <Route
              exact
              path={"/shop-single"}
              render={(props) => {
                this.onRouteChange("/shop-single");
                return <ShopSingle {...props} />;
              }}
            />
            {/* <Route
              exact
              path={"/cart"}
              render={(props) => <Cart {...props} />}
            /> */}
          </Switch>
        </HashRouter>
      </Router>
    );
  }
}

export default Routes;
