import React, { Component } from "react";
import { Link } from "react-router-dom";

class PageHeader extends Component {
  render() {
    return (
      <div
        className="cm-page-header overlay-dark bg-cover"
        style={{
          backgroundImage:
            "url(https://dacsa-web-media.s3.us-east-2.amazonaws.com/banners/mat-banner.jpeg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-sm-12 m-auto">
              <div className="block m-auto text-white text-start">
                <h1>{this.props.title}</h1>
              </div>
            </div>
            <div className="col-xl-6 col-sm-12 m-auto">
              <div className="block justify-content-center">
                {/* <img
                  src={"https://dacsa-web-media.s3.us-east-2.amazonaws.com/logos/ergonom-logo-white.png"}
                  alt=""
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageHeader;
