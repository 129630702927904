import React, { Component } from "react";
import { Link } from "react-router-dom";

class Cta extends Component {
  render() {
    return (
      <section
        className="section cm-cta bg-cover overlay-light"
        style={{
          backgroundImage:
            "url(https://dacsa-web-media.s3.us-east-2.amazonaws.com/banners/contact-banner.jpeg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <div className="text-dark">
                <h2 className="h1 mb-20">¡Contáctanos!</h2>
                <h4 className="mb-30">
                  442 4848928 | 442 7361620 para atención y ventas.
                </h4>
              </div>
              <a
                type="button"
                href="https://wa.me/524425925518"
                target="_blank"
                class="btn btn-primary initiate-scripts mr-10"
              >
                <span
                  style={{ color: "green" }}
                  className="fab fa-whatsapp"
                ></span>{" "}
                WhatsApp
              </a>
              <Link
                to={"/contact"}
                className="btn btn-primary initiate-scripts"
              >
                {" "}
                <span
                  style={{ color: "#b51929" }}
                  className="far fa-paper-plane"
                ></span>{" "}
                Contacto
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Cta;
