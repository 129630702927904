import React, { Component } from "react";
import ImageGallery from "react-image-gallery";

const images = {
  Antifatiga: {
    image1:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antifatiga/Antifatiga_04.jpg",
    image2:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antifatiga/Antifatiga_03.jpg",
    image3:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antifatiga/Antifatiga_02.jpg",
    image4:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antifatiga/Antifatiga_01.jpg",
  },
  Publicitario: {
    image1:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/publicitario/Publicitario_01.png",
    image2:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/publicitario/Publicitario_02.png",
    image3:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/publicitario/publicitario-home-1.jpg",
    image4:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/publicitario/Publicitario_03.jpg",
  },
  Antiestatico: {
    image1:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antiestatico/Antiestatico_01.png",
    image2:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antiestatico/antiestatico-home-1.jpeg",
    image3:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antiestatico/Antiestatico_03.png",
    image4:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/antiestatico/Antiestatico_02.png",
  },
  Dielectrico: {
    image1:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/dielectrico/Dialectrico_01.png",
    image2:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/dielectrico/Dialectrico_02.png",
    image3:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/dielectrico/dielectrico-home-1.jpeg",
    image4:
      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/products/dielectrico/Dialectrico_03.png",
  },
};
class Gallery extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const product = this.props.product;
    return (
      <>
        <section className="gallery">
          <div className="carousel">
            <input
              type="radio"
              id="image1"
              name="gallery-control"
              defaultChecked
            />
            <input type="radio" id="image2" name="gallery-control" />
            <input type="radio" id="image3" name="gallery-control" />
            <input type="radio" id="image4" name="gallery-control" />
            {/* <input
              type="checkbox"
              id="fullscreen"
              name="gallery-fullscreen-control"
            /> */}
            <div className="wrap">
              <figure>
                <label htmlFor="fullscreen">
                  <img
                    src={images[product].image1}
                    alt="image1"
                    title="Rollo Tapete Antifatiga"
                  />
                </label>
              </figure>
              <figure>
                <label htmlFor="fullscreen">
                  <img src={images[product].image2} alt="image2" />
                </label>
              </figure>
              <figure>
                <label htmlFor="fullscreen">
                  <img src={images[product].image3} alt="image3" />
                </label>
              </figure>
              <figure>
                <label htmlFor="fullscreen">
                  <img src={images[product].image4} alt="image4" />
                </label>
              </figure>
            </div>
            <div className="thumbnails">
              <div className="slider">
                <div className="indicator" />
              </div>
              <label
                htmlFor="image1"
                className="thumb"
                style={{
                  backgroundImage: `url("${images[product].image1}")`,
                }}
              />
              <label
                htmlFor="image2"
                className="thumb"
                style={{
                  backgroundImage: `url("${images[product].image2}")`,
                }}
              />
              <label
                htmlFor="image3"
                className="thumb"
                style={{
                  backgroundImage: `url("${images[product].image3}")`,
                }}
              />
              <label
                htmlFor="image4"
                className="thumb"
                style={{
                  backgroundImage: `url("${images[product].image4}")`,
                }}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Gallery;
