import React, { Component } from "react";
import ReactGA from "react-ga";
import Section from "./layouts/Section";
import { Button, Row, Col, Form, FormGroup, Input } from "reactstrap";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "Submit",
      isVerified: false,
      name: "",
      email: "",
      message: "",
      topic: "",
      phone: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  scrollToTop = (element) => {
    var elementPosition = document.getElementById(element).offsetTop;
    window.scrollTo({
      top: elementPosition - 100,
      behavior: "smooth",
    });
  };

  changeName = (event) => {
    this.setState({ name: event.target.value });
  };

  changeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  changeTopic = (event) => {
    this.setState({ topic: event.target.value });
  };

  changePhone = (event) => {
    this.setState({ phone: event.target.value });
  };

  changeMessage = (event) => {
    this.setState({ message: event.target.value });
  };

  onChange = (value) => {
    if (value) {
      // console.log("Captcha value:", value);
      this.setState({ isVerified: true });
    } else {
      console.log("Captcha errorer...");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    console.log("hi2");
    const { name, email, message, phone, topic } = this.state;
    const axios = require("axios").default;
    // if (this.state.isVerified) {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    await axios
      .post(
        "https://cxk9q89y4e.execute-api.us-east-2.amazonaws.com/dacsa-web/contacto",
        { name, email, message, phone, topic },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    alert("Mensaje enviado, ¡muy pronto te contactaremos!");
    // } else {
    //   alert("Error: Por favor completa el desafio ReCAPTCHA.");
    // }
    ReactGA.event({
      category: "Contact",
      action: "Contact request.",
    });
    this.scrollToTop("home");
    this.setState({
      name: "",
      email: "",
      message: "",
      topic: "",
      phone: "",
    });
  };

  render() {
    return (
      <Section title={"CONTACTO"}>
        <section className="section page-content">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <h1 style={{ marginBottom: 50 }}>Contácta con Nosotros</h1>
              <Col xs="12" md="12" lg="8">
                <Form className="mt-4" onSubmit={this.handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="nombre"
                          id="nombre"
                          value={this.state.name}
                          placeholder="Nombre*"
                          onChange={this.changeName}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          value={this.state.email}
                          placeholder="Correo Electónico*"
                          onChange={this.changeEmail}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="telefono"
                          id="telefono"
                          value={this.state.phone}
                          placeholder="Teléfono"
                          onChange={this.changePhone}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="asunto"
                          id="asunto"
                          value={this.state.topic}
                          placeholder="Asunto"
                          onChange={this.changeTopic}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="message"
                      id="message"
                      value={this.state.message}
                      placeholder="Mensaje"
                      onChange={this.changeMessage}
                    />
                  </FormGroup>
                  <Row>
                    <button
                      style={{ paddingLeft: 25 }}
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#modal"
                    >
                      Aviso de Privacidad
                    </button>

                    <div
                      className="modal fade bd-example-modal-lg"
                      id="modal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              AVISO DE PRIVACIDAD
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            AVISO DE PRIVACIDAD DE CHRISTIAN OBREGON GOMEZ en lo
                            sucesivo DACSA Seguridad, Y SUS MARCAS AFILIADAS Y
                            SUBSIDIARIAS. De conformidad en lo dispuesto por la
                            Ley Federal de Protección de Datos Personales en
                            Posesión de Particulares, se emite el presente Aviso
                            de Privacidad en los siguientes términos: IDENTIDAD
                            Y DOMICILIO DEL RESPONSABLE DE LOS DATOS PERSONALES:
                            Para efectos del presente Aviso de Privacidad, y sus
                            empresas afiliadas y subsidiarias (en adelante el
                            “EL RESPONSABLE”), señalan como encargado de la
                            obtención, divulgación, almacenamiento, uso,
                            incluido acción de acceso, manejo, aprovechamiento,
                            transferencia o disposición de datos personales (en
                            adelante el “Tratamiento”) al Comité de Privacidad
                            designado por EL RESPONSABLE, (en lo sucesivo el
                            “Comité de Privacidad”), con domicilio Micro parque
                            Tlacote II parcela Ejido Sta. Ma. Magdalena 250 Z-1
                            P1/4 Interior Nave 15 Ejido de Sta Ma. Magdalena
                            Querétaro, QRO. CP. 76138. INFORMACIÓN A RECABAR Y
                            FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES: Le
                            informamos que EL RESPONSABLE podrá recopilar su
                            información a través de diferentes fuentes, ya sea
                            Personal y/o Directamente proporcionados por el
                            Titular o Indirectamente por cualquier otra fuente
                            de información comercialmente disponibles o que sean
                            permitidas por la Ley. La colecta y Tratamiento de
                            datos personales del Titular, incluyendo los datos
                            personales sensibles que en su caso lleguemos a
                            recabar, y salvo que el Titular disponga lo
                            contrario mediante el medio descrito en el presente
                            Aviso de Privacidad, tiene como finalidad, la
                            evaluación de solicitudes de empleo, contratación de
                            empleados, evaluación de contratistas, propuestas y
                            contratación con proveedores de servicios y
                            productos, elaboración y manejo de contratos
                            comerciales, procesos de pago, así como para el
                            cumplimiento con nuestras obligaciones laborales,
                            administrativas, comerciales y fiscales
                            establecimiento de bases de datos de proveedores,
                            desarrollo de nuevos productos y servicios,
                            asesoría, comercialización, promoción, contratación
                            y colocación de todo tipo de servicios y productos
                            y/o mejora de nuestros productos y servicios y otras
                            obligaciones derivadas de cualquier relación
                            jurídica entre el titular de datos personales y EL
                            RESPONSABLE o las empresas afiliadas y subsidiarias
                            de EL RESPONSABLE. Sus datos personales, también
                            podrán tener, entre otras, las siguientes
                            finalidades: (i) Atender requerimientos legales de
                            autoridades competentes; (ii) Hacer de su
                            conocimiento, la gama de nuevos productos y
                            servicios, así como beneficios, descuentos, estudios
                            de mercado, promociones, notificaciones sobre cambio
                            en condiciones y en general toda la publicidad
                            derivada de los productos y servicios que ofrece EL
                            RESPONSABLE y/o las empresas afiliadas y
                            subsidiarias de EL RESPONSABLE; (iii) Análisis y
                            desarrollo de productos y de proveedores así como el
                            uso de nuestros productos y servicios; y (iv) Para
                            el cumplimiento de nuestros términos y condiciones
                            con relación a nuestras obligaciones, convenios,
                            contratos, así como para el desarrollo de productos,
                            venta de productos o la prestación de nuestros
                            servicios; y (v) en su caso, para el uso de nuestras
                            áreas de recursos humanos y compras, de conformidad
                            a las políticas de estas ultimas.*Los datos
                            sensibles que se pudieran recabar podrán ser
                            utilizados para el mejor cumplimiento de nuestros
                            objetivos legales, fiscales o comerciales. OPCIONES
                            Y MEDIOS QUE EL RESPONSABLE, OFRECE A LOS TITULARES
                            DE DATOS PERSONALES PARA LIMITAR EL USO O
                            DIVULGACIÓN DE SU INFORMACIÓN:Los datos personales
                            del titular, serán mantenidos en estricta
                            confidencialidad, de conformidad con las medidas de
                            seguridad, administrativas, técnicas y físicas que
                            al efecto EL RESPONSABLE ha implementado y las que
                            en el futuro implemente en sus políticas y
                            procedimientos de seguridad, quedando prohibido su
                            divulgación ilícita y limitando su uso a terceros,
                            conforme a lo previsto en el presente Aviso de
                            Privacidad. MEDIOS PARA EL EJERCICIO DE DERECHOS DEL
                            TITULAR: El titular de datos personales, podrá
                            ejercer sus derechos de acceso, rectificación,
                            cancelación, oposición, limitación de uso,
                            divulgación o revocación, a partir del 6 de enero de
                            2013, mediante solicitud por escrito enviada vía
                            correo postal o correo electrónico dirigidos a: Por
                            correo postal o mensajería privada:l Comité de
                            Privacidad de DACSA Seguridad. Micro parque Tlacote
                            II parcela Ejido Sta. Ma. Magdalena 250 Z-1 P1/4
                            Interior Nave 15 Ejido de Sta Ma. Magdalena
                            Querétaro, QRO. CP. 76138. Por correo electrónico a:
                            ventas1dacsa@outlook.com Mediante solicitud por
                            escrito que contenga la siguiente información: (i)
                            El nombre completo del titular de los datos
                            personales, domicilio y cuenta de correo
                            electrónico, para poder comunicarle la respuesta a
                            su solicitud; (ii) Los documentos que acrediten la
                            identidad y en su caso la representación legal del
                            titular de los datos personales; (iii) La
                            descripción clara y precisa de los datos personales
                            respecto de los que se busca ejercer alguno de los
                            derechos antes mencionados; (iv) Cualquier otro
                            elemento o documento que facilite la localización de
                            los datos personales; y (v) En el caso de
                            solicitudes de rectificación de datos personales, el
                            titular, deberá indicar, las modificaciones a
                            realizarse y aportar la documentación que sustente
                            su petición. TRANSFERENCIA DE DATOS PERSONALES. EL
                            RESPONSABLE podrá realizar la transferencia de datos
                            a: (i) Las empresas afiliadas y subsidiarias de
                            DACSA Seguridad a nivel internacional; (ii) Terceros
                            proveedores de servicios para el cumplimiento de las
                            obligaciones legales adquiridas por DACSA Seguridad
                            o por cualquiera de las empresas afiliadas y
                            subsidiarias (iii) Terceros proveedores de servicios
                            de investigación, análisis de datos, envío de
                            información enfocada a las necesidades del titular
                            de datos personales, así como en la realización de
                            otros servicios financieros necesarios o requeridos
                            por el titular de datos personales; (iv) Socios
                            comerciales con los que , lleve a cabo convenios o
                            contratos enfocados al desarrollo de nuevos
                            productos y/o servicios enfocados al mejoramiento y
                            de acuerdo a sus necesidades e investigación; (v)
                            Terceros derivado de una reestructura corporativa,
                            incluyendo, la fusión, consolidación, venta,
                            liquidación o transferencia de activos; y (vi) Otras
                            transmisiones previstas en la Ley citada y su
                            Reglamento. *Los terceros y las entidades receptores
                            de datos personales, asumen las mismas obligaciones
                            y/o responsabilidades de conformidad con lo descrito
                            en el presente Aviso de Privacidad. *El titular de
                            datos personales que presenta cualquier solicitud
                            para entablar una relación jurídica con, acepta la
                            transferencia de sus datos personales, en los
                            términos descritos en el presente Aviso de
                            Privacidad. CAMBIOS AL AVISO DE PRIVACIDAD:
                            Cualquier cambio al presente aviso de privacidad, le
                            será informado a través de cualquiera de los
                            siguientes medios: (i) Avisos en las plantas de EL
                            RESPONSABLE; (ii) Avisos en el portal de Internet ;
                            y (iii) Cualquier otro medio de comunicación público
                            o privado previstos en los contratos que amparan las
                            operaciones celebradas entre Christian Obregon Gomez
                            DACSA Seguridad FECHA DE EMISIÓN O ÚLTIMA
                            ACTUALIZACIÓN: Enero de 2019 El Comité de Privacidad
                            será en todo tiempo conformado por representantes de
                            áreas funcionales y designados por DACSA Seguridad
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Cerrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Button
                    // disabled={
                    //   !this.state.isVerified ||
                    //   this.state.email === "" ||
                    //   this.state.name === "" ||
                    //   this.state.message === ""
                    // }
                    className="float-right"
                    color="secondary"
                  >
                    Enviar
                  </Button>
                </Form>
              </Col>
            </div>
          </div>
        </section>
      </Section>
    );
  }
}

export default Services;
