import React, { Component } from "react";
import { Link } from "react-router-dom";

class BlogArea extends Component {
  render() {
    return (
      <section className="section cm-blogs bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="section-heading h1 text-dark mb-15">
                ¡Conoce nuestro contenido digital!
              </h2>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-item card border-0 mt-30">
                <a
                  className="initiate-scripts"
                  href="https://www.facebook.com/Dacsaseguridad"
                >
                  <img
                    className="card-img-top"
                    src={
                      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/logos/fb-img.jpeg"
                    }
                    alt=""
                  />
                </a>
                <div className="card-body">
                  <a
                    href="https://www.facebook.com/Dacsaseguridad"
                    target="_blank"
                    className="h4 mt-10 font-w-400 text-dark line-h-1-5 initiate-scripts"
                  >
                    Síguenos en Facebook
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-item card border-0 mt-30">
                <Link className="initiate-scripts" to={"/blog-details"}>
                  <img
                    className="card-img-top"
                    src={
                      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/logos/blog-img.jpeg"
                    }
                    alt=""
                  />
                </Link>
                <div className="card-body">
                  <Link
                    to={"/blog-details"}
                    className="h4 mt-10 font-w-400 text-dark line-h-1-5 initiate-scripts"
                  >
                    Blog Ergonomía
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-item card border-0 mt-30">
                <a
                  className="initiate-scripts"
                  href="https://www.youtube.com/channel/UCw-sq0btDuJqWYhoXGcP9Pg"
                >
                  <img
                    className="card-img-top"
                    src={
                      "https://dacsa-web-media.s3.us-east-2.amazonaws.com/logos/youtube-img.jpeg"
                    }
                    alt=""
                  />
                </a>
                <div className="card-body">
                  <a
                    href="https://www.youtube.com/channel/UCw-sq0btDuJqWYhoXGcP9Pg"
                    target="_blank"
                    className="h4 mt-10 font-w-400 text-dark line-h-1-5 initiate-scripts"
                  >
                    Visíta nuestro canal de Youtube
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BlogArea;
