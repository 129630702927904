import React, { Component } from "react";
import { Link } from "react-router-dom";

class RecentProducts extends Component {
  render() {
    return (
      <section className="section bg-light-gray">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center">
              <h2 className="section-heading h1 text-dark mb-30">
                Nuestros Productos
              </h2>
              <p className="mb-40">
                Do commanded an shameless we disposing do. Indulgence ten
                remarkably nor are impression out.
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-fluid">
          <div className="row no-gutters justify-content-center">
            <div className="col-md-12">
              <div className="owl-carousel fluid-carousel">
                <div className="card computer border-0 product-item">
                  <img
                    className="card-img-top border-bottom"
                    src={
                      "https://image.shutterstock.com/z/stock-photo-rubber-floor-mat-141586801.jpg"
                    }
                    alt=""
                  />
                  <div className="card-body">
                    <div className="show-on-hover">
                      <Link
                        to={"/contact"}
                        className="btn btn-primary rounded-pill initiate-scripts"
                      >
                        <span
                          style={{ color: "green", fontSize: 20 }}
                          className="fas fa-phone-volume"
                        />{" "}
                        ¡Contáctanos!
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <small className="text-uppercase font-w-600">
                        Tapetes Sanitizantes
                      </small>
                    </div>
                    <h5 className="text-dark font-w-600 mt-10 mb-10">
                      Tapete 1
                    </h5>
                  </div>
                </div>
                {/* product-item */}
                <div className="card computer border-0 product-item">
                  <img
                    className="card-img-top border-bottom"
                    src={
                      "https://image.shutterstock.com/z/stock-photo-rubber-floor-mat-141586801.jpg"
                    }
                    alt=""
                  />
                  <div className="card-body">
                    <div className="show-on-hover">
                      <Link
                        to={"/contact"}
                        className="btn btn-primary rounded-pill initiate-scripts"
                      >
                        <span
                          style={{ color: "green", fontSize: 20 }}
                          className="fas fa-phone-volume"
                        />{" "}
                        ¡Contáctanos!
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <small className="text-uppercase font-w-600">
                        Tapetes Sanitizantes
                      </small>
                    </div>
                    <h5 className="text-dark font-w-600 mt-10 mb-10">
                      Tapete 2
                    </h5>
                  </div>
                </div>
                {/* product-item */}
                <div className="card computer border-0 product-item">
                  <img
                    className="card-img-top border-bottom"
                    src={
                      "https://image.shutterstock.com/z/stock-photo-rubber-floor-mat-141586801.jpg"
                    }
                    alt=""
                  />
                  <div className="card-body">
                    <div className="show-on-hover">
                      <Link
                        to={"/contact"}
                        className="btn btn-primary rounded-pill initiate-scripts"
                      >
                        <span
                          style={{ color: "green", fontSize: 20 }}
                          className="fas fa-phone-volume"
                        />{" "}
                        ¡Contáctanos!
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <small className="text-uppercase font-w-600">
                        Tapetes Sanitizantes
                      </small>
                    </div>
                    <h5 className="text-dark font-w-600 mt-10 mb-10">
                      Tapete 3
                    </h5>
                  </div>
                </div>
                {/* product-item */}
                <div className="card computer border-0 product-item">
                  <img
                    className="card-img-top border-bottom"
                    src={
                      "https://image.shutterstock.com/z/stock-photo-rubber-floor-mat-141586801.jpg"
                    }
                    alt=""
                  />
                  <div className="card-body">
                    <div className="show-on-hover">
                      <Link
                        to={"/contact"}
                        className="btn btn-primary rounded-pill initiate-scripts"
                      >
                        <span
                          style={{ color: "green", fontSize: 20 }}
                          className="fas fa-phone-volume"
                        />{" "}
                        ¡Contáctanos!
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <small className="text-uppercase font-w-600">
                        Tapetes Sanitizantes
                      </small>
                    </div>
                    <h5 className="text-dark font-w-600 mt-10 mb-10">
                      Tapete 4
                    </h5>
                  </div>
                </div>
                {/* product-item */}
                <div className="card computer border-0 product-item">
                  <img
                    className="card-img-top border-bottom"
                    src={
                      "https://image.shutterstock.com/z/stock-photo-rubber-floor-mat-141586801.jpg"
                    }
                    alt=""
                  />
                  <div className="card-body">
                    <div className="show-on-hover">
                      <Link
                        to={"/contact"}
                        className="btn btn-primary rounded-pill initiate-scripts"
                      >
                        <span
                          style={{ color: "green", fontSize: 20 }}
                          className="fas fa-phone-volume"
                        />{" "}
                        ¡Contáctanos!
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <small className="text-uppercase font-w-600">
                        Tapetes Sanitizantes
                      </small>
                    </div>
                    <h5 className="text-dark font-w-600 mt-10 mb-10">
                      Tapete 5
                    </h5>
                  </div>
                </div>
                {/* product-item */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RecentProducts;
